<template>
  <v-dialog v-model="opened" persistent max-width="600px">
    <v-card>
      <v-card-title>
        {{ $t('phone_number_verification_title') }}
      </v-card-title>
      <v-card-text>
        <v-container>
          <template v-if="!requesting_code">
            <v-row>
              <span class="code-is-sent">{{ $t('verification_code_is_sent') }}</span>
            </v-row>
            <v-row>
              <v-col cols="9">
                <v-otp-input v-model="verification_code" color="orange" :label="$t('verification_code')" length="4" type="number" />
              </v-col>
              <v-col cols="3">
                <div class="verify-button">
                  <v-btn color="primary darken-1" @click="submit">
                    {{ $t('verify') }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <v-container style="height: 100px;">
              <v-row class="fill-height" align-content="center" justify="center">
                <v-col class="text-subtitle-1 text-center" cols="12">
                  {{ $t('sending_your_code') }}
                </v-col>
                <v-col cols="6">
                  <v-progress-linear color="deep-purple accent-4" indeterminate rounded height="6" />
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on">
                  <v-btn :disabled="!!code_sent_at" color="green darken-1" text @click="requestCode" v-bind="attrs">
                    {{ $t('send_another_code') }}
                  </v-btn>
                </div>
              </template>
              <span v-if="code_sent_at">{{ $t('you_cannot_request_another_code_right_now') }} <countdown v-if="code_sent_at" @finish="timerReset" :date="code_sent_at" without-hours mini /></span>
            </v-tooltip>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn color="green darken-1" text @click="closeDialog">
              {{ $t('close') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Countdown from '@/components/Core/Countdown'

export default {
  name: 'PhoneNumberVerificationModal',
  components: {
    Countdown
  },
  data () {
    return {
      opened: false,
      verification_code: '',
      code_sent_at: false,
      requesting_code: true
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      requestPhoneNumberVerification: 'user/requestPhoneNumberVerification',
      verifyPhoneNumber: 'user/verifyPhoneNumber',
      pushMessage: 'ui/pushMessage'
    }),
    requestCode () {
      this.requesting_code = true
      this.requestPhoneNumberVerification({
        token: this.token
      })
          .then(result => {
            if (result.status === 'success') {
              this.code_sent_at = result.data.expires_at
            }
          })
          .finally(() => {
            this.requesting_code = false
          })
    },
    submit () {
      this.verifyPhoneNumber({
        token: this.token,
        payload: {
          code: this.verification_code
        }
      })
        .then(result => {
          if (result.status === 'success') {
            this.pushMessage({
              status: 'success',
              message: result.message,
              delay: 2000
            })
            this.closeDialog()
          }
        })
    },
    openDialog () {
      this.opened = true
      this.requestCode()
    },
    closeDialog () {
      this.opened = false
      this.verification_code = ''
    },
    timerReset () {
      this.code_sent_at = false
    }
  }
}
</script>
<style scoped>
.code-is-sent {
  font-size: 14px;
  line-height: 100%;
  color: #649187;
}

.verify-button {
  margin: 10px;
  text-align: center;
}
</style>
