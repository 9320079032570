import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// const loadedLanguages = ['en-US']
let languages = { 'uk-UA': require('../resource/i18n/uk-UA.json'), 'en-US': require('../resource/i18n/en-US.json')}
const i18n = new VueI18n({
  locale: 'uk-UA',
  fallbackLocale: 'uk-UA',
  messages: languages
});
export default i18n

