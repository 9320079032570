import request from '@/server/request'

export default {
  async loadBuying ({ commit }) {
    return request({ entity: 'trade', action: 'load' }, null, 'GET')
    .then(async result => {
      if (result.items) {
        commit('setBuying', result.items)
      }
    })
  },
  async loadSelling ({ commit }) {
    return request({ entity: 'trade', action: 'load' }, null, 'GET', { type: 'sell'})
    .then(async result => {
      if (result.items) {
        commit('setSelling', result.items)
      }
    })
  }
}
