<template>
  <section>
    <v-snackbar :value="snackbarState" :timeout="-1" app :color="snackbarState.color">
      {{ snackbarState.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="openVerification">
          {{ $t('begin_verification') }}
        </v-btn>
      </template>
    </v-snackbar>
    <phone-number-verification ref="verificationDialog" />
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import PhoneNumberVerification from '@/components/User/Verification/Modals/PhoneNumberVerification'

export default {
  name: 'PhoneNumberSnackbar',
  components: {
    PhoneNumberVerification
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
    snackbarState () {
      let configs = {}
      if (!this.user.phone_verification) {
        configs.color = '#D65D5D'
        configs.message = this.$t('phone_needs_to_be_verified')
      }
      return configs
    }
  },
  methods: {
    openVerification () {
      this.$refs.verificationDialog.openDialog()
    }
  }
}
</script>
