import request from '@/server/request'

export default {
  loadBuyRequests({ rootGetters }) {
    return request({ entity: 'user.buy_request', action: 'list' }, rootGetters['user/getAccessToken'], 'GET')
  },
  cancel ({ rootGetters }, payload) {
    return request({ entity: 'user.buy_request', action: 'cancel' }, rootGetters['user/getAccessToken'], 'POST', payload)
  }
}
