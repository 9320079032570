import request from '@/server/request'

export default {
  async loadProducts ({ commit, rootGetters }) {
    console.log(rootGetters['user/getAccessToken'])
    return request({ entity: 'trade.products', action: 'load' }, rootGetters['user/getAccessToken'], 'GET')
    .then(async result => {
      if (result.items) {
        commit('setProducts', result.items)
      }
    })
  }
}
