import request from '@/server/request'

export default {
  async loadInventory({ commit, rootGetters }) {
    return request({ entity: 'user.inventory', action: 'list' }, rootGetters['user/getAccessToken'], 'GET')
      .then(result => {
        commit('setInventory', result.data)
        return result
      })
  },

  // eslint-disable-next-line no-empty-pattern
  async sellItem({}, { token, payload}) {
    return request({ entity: 'user.inventory.item', action: 'sell' }, token, 'POST', payload)
  },

  async loadLotsList({ commit }, token) {
    return request({ entity: 'user.inventory.lots', action: 'list' }, token, 'GET')
      .then(result => {
        commit('setLotsList', result.data)
        return result
      })
  }
}
