import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import buy_request from './buy_request'
import grower from './grower'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    buy_request,
    grower
  }
}
