<template>
  <div class="menu-container d-flex flex-column flex-1 align-items-center justify-content-center">
    <nav>
      <ul>
        <template v-for="(item, i) in items">
          <li v-if="item.allowed && item.type === 'link'" @click="linkClick(item, i)" :key="i">
            <router-link :to="item.route" class="d-flex justify-content-center align-items-center link"
                         :class="{ isActive: i === active }" :event="!item.disabled ? 'click' : ''"
            >
              <v-icon>{{ item.icon }}</v-icon>
              <span>{{ item.title }}</span>
            </router-link>
          </li>
        </template>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'NavigationMobile',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    active() {
      return this.items.indexOf(this.items.find(i => i.route === this.$route.path))
    }
  }
}
</script>
<style scoped lang="scss">
.menu-container {
  display: none!important;
}

@media screen and (max-width: 768px) {
  .menu-container {
    display: unset!important;
  }
}

/*
-- Values
*/
$nav-list-item-width: 25%;
$nav-link-margin: 10px;
$nav-link-height: 80px;
$nav-link-width: 100%;
$nav-icon-size: 32px;
$nav-circle-size: 5px;
$nav-circle-radius: 50%;
$nav-link-font-size: 14px;
$nav-link-font-weight: 500;
$nav-link-text-z-index: 1;
$nav-link-text-mask-z-index: 2;
$nav-icon-z-index: 3;
$nav-icon-mask-z-index: 4;

/*
-- Colors
*/

$body-background-color: #fff;
$nav-background-color: #fff;
$nav-icon-color: #009357;
$nav-link-text-color: #009357;

.flex-column {
  flex-direction: column;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.flex-1 {
  flex-grow: 1;
}

/*
-- Nav
*/

nav {
  width: 100%;
  background-color: $nav-background-color;
  position: fixed;
  bottom: 0px;

  ul {
    list-style-type: none;
    padding: 10px;
    margin: auto;
  }

  li {
    display: inline-flex;
    width: $nav-list-item-width;
    //remove li spacing
    margin-right: -4px;
  }

  a {
    height: $nav-link-height;
    width: $nav-link-width;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    margin-right: $nav-link-margin;
    margin-left: $nav-link-margin;

    &:hover {
      i {
        transition: all 0.4s;
        color: $nav-link-text-color;
      }
    }

    i {
      position: absolute !important;
      z-index: $nav-icon-z-index;
      color: $nav-icon-color;
      font-size: $nav-icon-size!important;
      &:after {
        content: "";
        background-color: $nav-background-color;
        position: absolute;
        transform: rotate(15deg);
        right: 0;
        bottom: 0;
        left: -5px;
        height: 1px;
        z-index: $nav-icon-mask-z-index;
      }
    }

    span {
      color: $nav-link-text-color;
      font-size: $nav-link-font-size;
      font-weight: $nav-link-font-weight;
      z-index: $nav-link-text-z-index;
      transform: translateY(20px);
      position: relative;
      &:first-letter {
        text-transform: capitalize;
      }
      &:after {
        content: "";
        background-color: $nav-link-text-color;
        position: absolute;
        height: $nav-circle-size;
        width: $nav-circle-size;
        border-radius: $nav-circle-radius;
        display: flex;
        left: calc(50% - #{$nav-circle-size / 2});
        top: 30px;
        transform: scale(0);
        opacity: 0;
      }
    }

    &:after {
      content: "";
      background-color: $nav-background-color;
      position: absolute;
      transform: rotate(15deg);
      right: -2px;
      bottom: -14px;
      left: -8px;
      height: 50px;
      z-index: $nav-link-text-mask-z-index;
    }
  }

  @mixin onClick(
    $translateIcon,
    $IconMaskHeight,
    $translateSpan,
    $scaleCircle,
    $linkMaskHeight
  ) {
    i {
      transform: translateY($translateIcon);
      transition: all 0.8s;
      &:after {
        transition: all 0.6s;
        height: $IconMaskHeight;
      }
    }

    span {
      transform: translateY($translateSpan);
      transition: all 0.4s ease-in;
      &:after {
        transition: all 0.8s ease;
        transform: scale($scaleCircle);
        opacity: 1;
      }
    }
    &:after {
      height: $linkMaskHeight;
      transition: height 1s;
    }
  }

  //onClick
  a.isActive {
    @include onClick(-55px, $nav-icon-size, 0, 1, 10px);
  }

  //onClick other links
  a.notActive {
    @include onClick(0, 1px, 20px, 0, 50px);
  }
}

</style>
