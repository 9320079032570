import request from '@/server/request'

export default {
  async login({ dispatch }, credentials) {
    return request({ entity: 'user', action: 'login' }, null, 'POST', credentials)
      .then(async credentials => {
        if (credentials.token_type === 'Bearer' && credentials.access_token) {
          await dispatch('loadUserData', credentials.access_token)
            .then(user => {
              if (user.id) {
                dispatch('setAccessToken', credentials.access_token)
              }
            })
        }
      })
  },

  async logout({ dispatch }, token) {
    return request({ entity: 'user', action: 'logout' }, token, 'POST', null, false)
      .then(result => {
        dispatch('setAccessToken', null)
        dispatch('setUser', null)
        return result
      })
  },

  async socialLogin({ dispatch }, payload) {
    return request({ entity: 'user', action: 'social_login' }, null, 'POST', payload)
      .then(async credentials => {
        if (credentials.token_type === 'Bearer' && credentials.access_token) {
          await dispatch('loadUserData', credentials.access_token)
            .then(user => {
              if (user.id) {
                dispatch('setAccessToken', credentials.access_token)
              }
            })
        }
      })
  },


  // eslint-disable-next-line no-empty-pattern
  async register({}, userData) {
    return request({ entity: 'user', action: 'register' }, null, 'POST', userData)
  },

  // eslint-disable-next-line no-empty-pattern
  async requestPasswordReset({}, email) {
    return request({ entity: 'user', action: 'request_password_reset' }, null, 'POST', { email })
  },

  // eslint-disable-next-line no-empty-pattern
  async resetPassword({}, payload) {
    return request({ entity: 'user', action: 'reset_password' }, null, 'POST', payload)
  },

  async loadUserData({ commit }, token) {
    return request({ entity: 'user', action: 'me' }, token, 'GET')
      .then(user => {
        commit('setUser', user)
        return user
      })
  },

  // eslint-disable-next-line no-empty-pattern
  async verifyEmail({}, { token, payload }) {
    return request({ entity: 'user.verification', action: 'email' }, token, 'POST', payload)
  },

  async loadDocuments({ rootGetters }) {
    return request({ entity: 'user.documents', action: 'list' }, rootGetters['user/getAccessToken'])
  },

  async updateProfileInformation({ commit }, { token, payload }) {
    return request({ entity: 'user', action: 'update_profile_information' }, token, 'POST', payload)
      .then(user => {
        commit('updateProfileInformation', user)
      })
  },

  setAccessToken({ commit }, token) {
    commit('setAccessToken', token)
  },

  // eslint-disable-next-line no-empty-pattern
  async requestPhoneNumberVerification({}, { token }) {
    return request({ entity: 'user.verification', action: 'request_phone_number_code' }, token, 'POST')
  },

  async verifyPhoneNumber({ commit }, { token, payload }) {
    return request({ entity: 'user.verification', action: 'phone_number' }, token, 'POST', payload)
      .then(result => {
        if (result.status === 'success') {
          commit('verifyPhoneNumber')
        }
      })
  },

  // eslint-disable-next-line no-empty-pattern
  async changePassword({}, { token, payload }) {
    return request({ entity: 'user', action: 'change_password' }, token, 'POST', payload)
  },
}
