import * as configMain from '../config/local.json'
import * as configDefault from '../config/default.json'
import store from '@/store'
import router from '@/router'

let config = configDefault.default

export default function (
  { entity = null, action = null },
  token = null,
  method = 'GET',
  body = null,
  isJson = true,
  isFile = false
) {
  let url = buildServerUrl(entity, action)
  let headers = {
    'Accept': 'application/json'
  }
  if (isJson){
    headers['Content-Type'] = 'application/json'
  }
  if (store.getters['app/getXsrfToken'] && store.getters['app/getXsrfToken'].length) {
    headers['X-XSRF-TOKEN'] = store.getters['app/getXsrfToken']
  }
  let requestConfigs = {
    method,
    headers
  }
  if (token) {
    requestConfigs.headers['Authorization'] = `Bearer ${token}`
  }
  let keys = url.match(/({{)(\w*)(}})/g)
  if (keys) {
    keys.forEach(key => {
      url = url.replace(key, body[key.replace(/{|}/g, '')])
      delete body[key.replace(/{|}/g, '')]
    })
  }
  url = (body && method === 'GET') ? processUrlParams(url, body) : url
  if (body && method !== 'GET') {
    body = (body && typeof body !== 'string' && !isFile) ? JSON.stringify(body) : body
    requestConfigs.body = body
  }
  if (isJson) {
    return fetch(url, requestConfigs)
      .then(async res => {
        let response = await res.json()
        if (res.ok) {
          return response
        }
        let message = response.message ? response.message : ''
        if (res.status === 401) {
          message = 'Your authorization is expired. Please, login again!'
        }
        await store.dispatch('ui/pushMessage', {
          status: 'error',
          message,
          delay: 2000
        })
        if (res.status === 401) {
          store.commit('user/setUser', null)
          store.commit('user/setAccessToken', null)
          router.push('/sign-in')
        }
        throw {
          message,
          code: res.status
        }
      })
  } else {
    return fetch(url, requestConfigs)
      .then(async res => {
        if (res.ok) {
          return res
        }
        throw new Error('Something went wrong, err code: ' + res.statusCode)
      })
  }
}
export function buildServerUrl(entity, action) {
  if (entity.includes('.')) {
    return `${configMain.server.url}${entity.split('.').reduce((o,i) => o[i], config)[action]}`
  }
  validateUrlParams(entity, action)
  return `${configMain.server.url}${config[entity][action]}`
}

function validateUrlParams(entity, action, object = config) {
  if (!object[entity]) {
    throw new Error(`Requested entity ${entity} was not found in MS configs`)
  }
  if (!object[entity][action]) {
    throw new Error(`Requested action ${action} was not found in MS configs entity ${entity}`)
  }
}

function processUrlParams (baseUrl, params, firstItemProcessed = false) {
  Object.keys(params).forEach(key => {
    let char = firstItemProcessed ? '&' : '?'
    firstItemProcessed = true
    baseUrl = `${baseUrl}${char}${key}=${params[key]}`
  })

  return baseUrl
}
