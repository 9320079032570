export const USER_FIELDS = {
  id: null,
  name: null,
  email: null
}

export default {
  user: USER_FIELDS,
  access_token: null
}
