export default {
  showLoader ({ commit }, loaderText) {
    commit('showLoader', loaderText)
  },
  hideLoader ({ commit }) {
    commit('hideLoader')
  },
  pushMessage({ commit }, message) {
    commit('pushMessage', message)
  }
}
