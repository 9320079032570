import request from '@/server/request'

export default {
  loadChartData ({ rootGetters }, payload) {
    return request({ entity: 'statistics.chart_data', action: 'load' }, rootGetters['user/getAccessToken'], 'GET', payload)
  },
  loadProfileStatistic ({ rootGetters }) {
    return request({ entity: 'statistics.me', action: 'load' }, rootGetters['user/getAccessToken'], 'GET')
  }
}
