import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import Vuex from 'vuex'
import VueChartist from 'vue-chartist'
import store from './store'
import axios from 'axios'
import UniversalSocialauth from 'universal-social-auth'
import i18n from './plugins/i18n'
import './filters'

import * as configLocal from '@/config/local.json'

import './assets/scss/main.scss'
import 'chartist/dist/chartist.min.css'

let config = configLocal.default
Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(VueCookies)
Vue.use(VueChartist)

const options = {
  providers: {
    // apple: {
    //   nonce: '**************',
    //   state: '**************',
    //   clientId: '**************',
    //   redirectUri: 'https://myapp.com/auth/github/callback'
    // },
    google: {
      clientId: config.social_auth.google.client_id,
      redirectUri: config.social_auth.google.redirect_uri
    },
    facebook: {
      clientId: config.social_auth.facebook.client_id,
      redirectUri: config.social_auth.facebook.redirect_uri
    }
  }
}

const Oauth = new UniversalSocialauth(axios, options)

Vue.prototype.$axios = axios
Vue.prototype.$Oauth = Oauth

// Initialize Vue.js application
// eslint-disable-next-line
export default new Vue({
  el: '#app',
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
})
