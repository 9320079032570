<template>
  <section>
    <v-navigation-drawer app :mini-variant.sync="mini" permanent expand-on-hover class="desktop-navigation" >
      <v-list-item>
        <v-list-item-content v-if="!mini">
          <v-list-item-title class="text-h6">
            <v-img src="@/assets/logo.svg" />
          </v-list-item-title>
          <v-list-item-subtitle>
            Trading? Mining? Growing!
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
          <v-list-item-title class="text-h6">
            <v-img src="@/assets/logo_mini.svg" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <template v-for="(item, i) in items">
          <v-list-item v-if="item.allowed && item.type === 'link'" :key="i" :to="item.route" :disabled="item.disabled" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group v-else-if="item.allowed && item.type === 'group'" :key="i" no-action>
            <v-list-item slot='activator'>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(groupItem, j) in item.items" :key="`${i}-${j}`" :to="groupItem.route" link>
              <v-list-item-icon>
                <v-icon>{{ groupItem.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ groupItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
      <template v-slot:append>
        <div>
          <v-btn block @click="logoutUser">
            <v-icon v-if="mini" color="green darken-2" dense>
              mdi-logout
            </v-icon>
            <template v-else>
              {{ $t('logout') }}
            </template>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Navigation',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    mini: true
  }),
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
      pushMessage: 'ui/pushMessage'
    }),
    logoutUser () {
      this.logout(this.token)
        .then(() => {
          this.pushMessage({
            status: 'success',
            message: 'logged_out',
            delay: 2000
          })
          this.$router.push('/sign-in')
        })
    }
  }
}
</script>
<style scoped>
@media screen and (max-width: 768px) {
  .desktop-navigation {
    display: none;
  }
}
</style>
