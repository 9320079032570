import request from '@/server/request'

export default {
  async loadLots({ commit }, token) {
    return request({ entity: 'auction', action: 'load_lots' }, token, 'GET')
      .then(result => {
        commit('setLots', result.data)
        return result
      })
  },

  async buyLot({ commit }, { token, payload }) {
    return request({ entity: 'auction', action: 'buy' }, token, 'POST', payload)
      .then(result => {
        commit('setLots', result.data)
        return result
      })
  },

  // eslint-disable-next-line no-empty-pattern
  async cancel({}, { token, payload }) {
    return request({ entity: 'auction', action: 'cancel' }, token, 'POST', payload)
  },

  // eslint-disable-next-line no-empty-pattern
  async loadFeed({}) {
    return request({ entity: 'auction.feed', action: 'load' }, null, 'GET')
  }
}
