<template>
  <v-app>
    <navigation :items="items" />

    <v-app-bar class="mobile-header">
      <v-row>
        <v-col class="logo" cols="12" sm="3" md="6">
          <div>
            <v-img src="@/assets/logo.svg" />
          </div>
        </v-col>
        <v-col cols="12" sm="9" md="6" class="header-sign">
          Trading? Mining? Growing!
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view v-if="initialized"></router-view>
      </v-container>
    </v-main>

    <v-snackbar v-for="message in messages" :timeout="message.delay" :value="true" :key="message.id"
                app top right tile :color="message.color"
    >
      {{ message.message }}
    </v-snackbar>
    <verification v-if="user.id" />
    <v-footer app>
      <!-- -->
    </v-footer>
    <navigation-mobile :items="items" />
    <v-overlay :value="overlay || !initialized">
      <v-progress-circular v-if="loader || !initialized" :size="50" color="primary" indeterminate />
    </v-overlay>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Navigation from '@/components/Navigation'
import NavigationMobile from '@/components/NavigationMobile'
import Verification from '@/components/User/Verification/Verification'

export default {
  name: 'App',
  components: {
    Navigation,
    NavigationMobile,
    Verification
  },
  async created () {
    if (this.$cookies.get('XSRF-TOKEN')) {
      await this.setXsrfToken(this.$cookies.get('XSRF-TOKEN'))
    }
    if (localStorage.getItem('access_token')) {
      await this.setAccessToken(localStorage.getItem('access_token'))
      await this.loadUserData(localStorage.getItem('access_token'))
    }
    await this.initializeCsrf()
    this.initialized = true
  },
  computed: {
    ...mapGetters({
      overlay: 'ui/getOverlay',
      loader: 'ui/getLoader',
      loaderText: 'ui/getLoaderText',
      messages: 'ui/getMessages',
      user: 'user/getUser'
    }),
    items () {
      return [
        { type: 'link', title: this.$t('home'), icon: 'mdi-home', route: '/', disabled: false, allowed: this.authenticated },
        { type: 'link', title: this.$t('sign_in'), icon: 'mdi-login', route: '/sign-in', disabled: false, allowed: !this.authenticated },
        { type: 'link', title: this.$t('inventory'), icon: 'mdi-home-automation', route: '/me/inventory', disabled: false, allowed: this.authenticated },
        { type: 'link', title: this.$t('auction'), icon: 'mdi-storefront', route: '/auction', disabled: false, allowed: this.authenticated },
        { type: 'link', title: this.$t('profile'), icon: 'mdi-account', route: '/me/office', disabled: false, allowed: this.authenticated }
      ]
    },
    authenticated () {
      return !!this.user.id
    }
  },
  data: () => ({
    initialized: false
  }),
  methods: {
    ...mapActions({
      setAccessToken: 'user/setAccessToken',
      loadUserData: 'user/loadUserData',
      initializeCsrf: 'app/initializeCsrf',
      setXsrfToken: 'app/setXsrfToken'
    })
  }
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,700&display=swap');

.v-application {
  font-family: 'Raleway',sans-serif !important;
  &.v-list-item--active {
    color: #009357!important;
  }
  &.v-list-item {
    color: #649187!important;
  }
}

.v-label {
  color: #649187!important;
}

.v-text-field--outlined fieldset {
  border-color: #BDE5DD!important;
  border-radius: 10px!important;
}

.v-text-field .v-icon {
  color: #BDE5DD;
}

.mobile-header {
  display: none!important;
  max-height: 60px;
}

.main-button {
  color: #FFFFFF!important;
  font-weight: 600;
}

::-webkit-scrollbar {
  width: 5px;
  heigth: 5px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  border: 5px solid #BDE5DD;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  border: 5px solid #009357;
}

@media screen and (max-width: 768px) {
  .v-main {
    padding: 0!important;
  }

  .mobile-header {
    display: block!important;
  }

  .header-sign {
    text-align: end;
    align-self: center;
  }

  .container {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .header-sign {
    flex: 0 0 75%;
    max-width: 75%;
  }
}
</style>
<style lang="scss">
/* Vuetify overrides. TODO: Move into the appropriate file later */

/* TABS COMPONENT START */
.v-tabs--icons-and-text > .v-tabs-bar {
  background-color: unset!important;

  & .v-tabs-slider-wrapper {
    display: none;
  }

  & .v-tab {
    flex-direction: row!important;
    background-color: #ADD4CC;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    margin-right: 10px;
    color: #649187!important;
    font-weight: 600!important;

    & > *:first-child {
      margin-right: 10px;
      color: #649187!important;
    }
  }

  & .v-tab.v-tab--active {
    background-color: #FFFFFF;
    color: #009357!important;

    & > *:first-child {
      color: #009357!important;
    }
  }
}

.v-tabs > .v-tabs-items {
  border-radius: 15px;
  bottom: 10px;
}
/* TABS COMPONENT END */

/* MODAL COMPONENT START */
.v-dialog {
  border: 1px solid #BDE5DD;
  border-radius: 15px;

  & .v-card__title {
    font-weight: 400!important;
    font-size: 32px!important;
    color: #009357;
    justify-content: center;
  }

  & .v-card__text {
    //width: 80%!important;
    margin: auto!important;
  }

  & .modal-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #649187;
  }

  & .v-card__actions {
    justify-content: center;
    padding-bottom: 24px!important;

    & .v-btn {
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      border: 2px solid;
      border-radius: 5px;
      padding: 25px 55px;
    }

    & .v-btn.red--text {
      color: #D65D5D!important;
      border-color: #D65D5D;
    }

    & .v-btn.primary--text {
      border-color: #009357;
      background-color: #009357;
      color: #FFFFFF!important;
    }
  }
}
/* MODAL COMPONENT END */

/* OTHER START */
.divider {
  border-top: 2px solid #DAF0E9!important;
}

.v-sheet.v-snack__wrapper {
  border-radius: 10px;

  & .v-btn--text {
    font-size: 14px;
    font-weight: 700;
    text-decoration: underline;
  }
}
/* OTHER END */
</style>
