<template>
  <section>
    <v-snackbar :value="documentsStateSnackbar" :timeout="-1" app :color="documentsStateSnackbar.color">
      {{ documentsStateSnackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="openMyDocuments">
          {{ $t('begin_verification') }}
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DocumentsSnackbar',
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    }),
    documentsStateSnackbar () {
      let configs = {}
      if (this.user.documents_state === 0) {
        configs.color = '#D65D5D'
        configs.message = this.$t('you_have_to_upload_documents')
      }
      if (this.user.documents_state === 1) {
        configs.color = '#E4B63F'
        configs.message = this.$t('we_are_verifying_documents')
      }
      return configs
    }
  },
  methods: {
    openMyDocuments () {
      this.$router.push('/me/profile-settings#documents')
    }
  }
}
</script>
