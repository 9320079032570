import Vue from 'vue'
import Vuex from 'vuex'
import app from '@/store/app'
import auction from '@/store/auction'
import user from '@/store/user'
import ui from '@/store/ui'
import trade from '@/store/trade'
import payments from '@/store/payments'
import products from '@/store/products'
import smart_house from '@/store/smart_house'
import statistics from '@/store/statistics'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auction,
    user,
    ui,
    trade,
    payments,
    products,
    smart_house,
    statistics
  }
})
