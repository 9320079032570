import Vue from 'vue'
import Router from 'vue-router'

import auth from './middleware/auth'
import guest from './middleware/guest'

Vue.use(Router)

//import Home from '@/pages/Home'

import EmailVerification from '@/pages/Me/Verification/Email'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'home',
      path: '/',
      component: () => import(/* webpackChunkName: "user-office" */ './pages/Office.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      name: 'office',
      path: '/me/office',
      component: () => import(/* webpackChunkName: "user-office" */ './pages/Office.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      name: 'authentication',
      path: '/sign-in',
      component: () => import(/* webpackChunkName: "user-auth" */ './pages/Authentication.vue'),
      meta: {
        middleware: guest
      }
    },
    {
      path: '/user/reset-password-request',
      name: 'reset-password-request',
      component: () => import(/* webpackChunkName: "password-reset-request" */ './pages/ResetPasswordRequest.vue'),
      meta: {
        middleware: guest
      }
    },
    {
      path: '/user/reset-password/:email/:token',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "password-reset" */ './pages/ResetPassword.vue'),
      meta: {
        middleware: guest
      }
    },
    {
      name: 'user-change-password',
      path: '/me/change-password',
      component: () => import(/* webpackChunkName: "user-change-password" */ './pages/Me/ChangePassword.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      name: 'user-settings',
      path: '/me/profile-settings',
      component: () => import(/* webpackChunkName: "user-settings" */ './pages/Me/ProfileSettings.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      name: 'user-inventory',
      path: '/me/inventory',
      component: () => import(/* webpackChunkName: "user-inventory" */ './pages/Me/Inventory.vue'),
      meta: {
        middleware: auth
      }
    },
    {
      name: 'user-email-verify',
      path: '/me/email/verify',
      component: EmailVerification
    },
    {
      name: 'auction',
      path: '/auction',
      component: () => import(/* webpackChunkName: "auction" */ './pages/Auction.vue'),
    },
    {
      path: '/auth/:provider/callback',
      component: {
        template: '<div class="auth-component"></div>'
      }
    }
  ]
})

function middlewarePipeline (context, middleware, index) {
  const nextMiddleware = middleware[index]
  if(!nextMiddleware){
    return context.next
  }
  return () => {
    const nextPipeline = middlewarePipeline(
      context, middleware, index + 1
    )
    nextMiddleware({ ...context, next: nextPipeline })
  }
}

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next()
  }
  const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]

  const context = {
    from,
    next,
    router,
    to
  }

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

export default router
