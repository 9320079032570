<template>
  <section>
    <phone-number-snackbar v-if="!user.phone_verified" />
    <documents-snackbar v-else-if="user.documents_state !== 2" />
  </section>
</template>
<script>
import { mapGetters } from 'vuex'
import PhoneNumberSnackbar from '@/components/User/Verification/PhoneNumberSnackbar'
import DocumentsSnackbar from '@/components/User/Verification/DocumentsSnackbar'

export default {
  name: 'VerificationBarsSection',
  components: {
    PhoneNumberSnackbar,
    DocumentsSnackbar
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser'
    })
  }
}
</script>
