import request from '@/server/request'

export default {
  async loadBuyRequests({ commit, rootGetters }, payload) {
    return request({ entity: 'auction.buy_request', action: 'list' }, rootGetters['user/getAccessToken'], 'GET', payload)
      .then(result => {
        commit('setBuyRequests', result.data)
        return result
      })
  },
  loadProducts({ rootGetters }) {
    return request({ entity: 'auction.buy_request', action: 'load_products' }, rootGetters['user/getAccessToken'], 'GET')
  },
  create ({ rootGetters }, payload) {
    return request({ entity: 'auction.buy_request', action: 'create' }, rootGetters['user/getAccessToken'], 'POST', payload)
  }
}
