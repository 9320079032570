<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Email verification
        </h1>

        <p>
          {{ message }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MeVerificationEmail',
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  data: () => ({
      message: 'Please, check your email. You have to open a link that allows you to verify it and activate your account!'
  }),
  methods: {
    ...mapActions({
      verify: 'user/verifyEmail',
      pushMessage: 'ui/pushMessage'
    })
  },
  mounted () {
    if (this.$route.query.id && this.$route.query.hash) {
      this.message = 'Please, wait! Verification is in progress!'
      this.verify({
        token: this.token,
        payload: {
          id: this.$route.query.id,
          hash: this.$route.query.hash
        }
      })
        .then(() => {
          this.message = 'Your email is verified successfully!'
          this.pushMessage({
            status: 'success',
            message: this.message,
            delay: 2000
          })
        })
        .catch(() => {
          this.message = 'Your email verification is failed! Please, contact the support!'
        })
    }
  }
}
</script>
